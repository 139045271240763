var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
// @mui
import { Box, Button, IconButton, MenuItem, TableCell, TableRow, Tooltip, Typography, } from '@mui/material';
import { fDate } from '../../../../utils';
import ConfirmDialog from '../../../../components/confirm-dialog';
import Iconify from '../../../../components/iconify';
import MenuPopover from '../../../../components/menu-popover';
import usePermission from '../../../../hooks/usePermission';
export default function LevelTableRow(_a) {
    var row = _a.row, selected = _a.selected, onEditRow = _a.onEditRow, onSelectRow = _a.onSelectRow, onDeleteRow = _a.onDeleteRow, onViewRow = _a.onViewRow;
    var name = row.name, description = row.description, createdAt = row.createdAt;
    var _b = useState(false), openConfirm = _b[0], setOpenConfirm = _b[1];
    var _c = useState(null), openPopover = _c[0], setOpenPopover = _c[1];
    var _d = usePermission(), isSuperAdmin = _d.isSuperAdmin, hasPermissionViewLevel = _d.hasPermissionViewLevel, hasPermissionEditLevel = _d.hasPermissionEditLevel, hasPermissionDeleteLevel = _d.hasPermissionDeleteLevel;
    var isAllowedToViewLevel = isSuperAdmin || hasPermissionViewLevel;
    var isAllowedToEditLevel = isSuperAdmin || hasPermissionEditLevel;
    var isAllowedToDeleteLevel = isSuperAdmin || hasPermissionDeleteLevel;
    var handleOpenConfirm = function () {
        setOpenConfirm(true);
    };
    var handleCloseConfirm = function () {
        setOpenConfirm(false);
    };
    var handleOpenPopover = function (event) {
        setOpenPopover(event.currentTarget);
    };
    var handleClosePopover = function () {
        setOpenPopover(null);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(TableRow, __assign({ hover: true, selected: selected }, { children: [_jsx(TableCell, { children: _jsx(Typography, __assign({ variant: "subtitle2", noWrap: true }, { children: name || '_____' })) }), _jsx(TableCell, __assign({ align: "left" }, { children: _jsx(Tooltip, __assign({ title: description }, { children: _jsx(Box, __assign({ sx: {
                                    maxWidth: 125,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                } }, { children: description || '_____' })) })) })), _jsx(TableCell, __assign({ align: "center" }, { children: fDate(createdAt) })), _jsx(TableCell, __assign({ align: "center" }, { children: _jsx(IconButton, __assign({ color: openPopover ? 'inherit' : 'default', onClick: handleOpenPopover }, { children: _jsx(Iconify, { icon: "eva:more-vertical-fill" }) })) }))] })), _jsxs(MenuPopover, __assign({ open: openPopover, onClose: handleClosePopover, arrow: "right-top", sx: { width: 140 } }, { children: [isAllowedToViewLevel && (_jsxs(MenuItem, __assign({ onClick: function () {
                            onViewRow();
                            handleClosePopover();
                        } }, { children: [_jsx(Iconify, { icon: "carbon:view-filled" }), "View"] }))), isAllowedToEditLevel && (_jsxs(MenuItem, __assign({ onClick: function () {
                            onEditRow();
                            handleClosePopover();
                        } }, { children: [_jsx(Iconify, { icon: "eva:edit-fill" }), "Edit"] }))), isAllowedToDeleteLevel && (_jsxs(MenuItem, __assign({ onClick: function () {
                            handleOpenConfirm();
                            handleClosePopover();
                        }, sx: { color: 'error.main' } }, { children: [_jsx(Iconify, { icon: "eva:trash-2-outline" }), "Delete"] })))] })), _jsx(ConfirmDialog, { open: openConfirm, onClose: handleCloseConfirm, title: "Delete", content: "Are you sure want to delete?", action: _jsx(Button, __assign({ variant: "contained", color: "error", onClick: function () {
                        onDeleteRow();
                        handleCloseConfirm();
                    } }, { children: "Delete" })) })] }));
}
